// Name:            Badge
// Description:     Component to create notification badges

// Component:       `uk-badge`
//
// ========================================================================


// Variables
// ========================================================================

$badge-size:                                     22px !default;
$badge-padding-vertical:                         0 !default;
$badge-padding-horizontal:                       5px !default;
$badge-border-radius:                            0 !default;
$badge-background:                               $global-primary-background !default;
$badge-color:                                    $global-inverse-color !default;
$badge-font-size:                                $global-small-font-size !default;

$badge-hover-color:                              $global-inverse-color !default;


// Inverse
// ========================================================================

$inverse-badge-background:                      $inverse-global-primary-background !default;
$inverse-badge-color:                           $global-link-color;
$inverse-badge-hover-color:                     $global-link-hover-color !default;
