// Name:            Sortable
// Description:     Component to create sortable grids and lists
//
// Component:       `uk-sortable`
//
// Sub-objects:     `uk-sortable-drag`
//                  `uk-sortable-placeholder`
//                  `uk-sortable-handle`
//
// Modifiers:       `uk-sortable-empty`
//
// States:          `uk-drag`
//
// ========================================================================


// Variables
// ========================================================================

$sortable-dragged-z-index:                       $global-z-index + 50 !default;

$sortable-placeholder-opacity:                   0 !default;

$sortable-empty-height:                          50px !default;

