.lf-newsletter-signup {
  & .lf-contribute-headline {
    @media (min-width: $breakpoint-medium) {
      max-width: 460px;
    }
  }

  & .lf-contribute-container {
    border: 1px solid $global-link-color !important;

    @media (max-width: $breakpoint-small-max) {
      padding: 30px !important;
      border-left: none !important;
      border-right: none !important;

      & .uk-padding {
        padding: 0 !important;
      }

      & input[type='email'], & input[type='text'] {
        margin: 15px 0;
      }
    }
  }

  & .uk-padding-large {
    @media (max-width: $breakpoint-small-max) {
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-bottom: 30px;
    }
  }

  input[type='email'], input[type='text'] {
    background: none;
    border: none;
    border-bottom: 1px solid $global-muted-color;
    font-family: $global-font-family;
    padding: .75em;
    width: calc(100% - 1.5em);
    position: relative;
    transition-duration: .5s;
    font-size: inherit !important;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $global-muted-color;
    }
  }

  &:nth-of-type(2) {
    @extend .uk-padding;
  }
}

.lf-form {
  @extend .uk-section-muted;
  @extend .uk-padding;

  overflow: hidden;

  & + .lf-form {
    background: none;
  }

  & label {
    display: none;
  }

  & input:not(.multiselect__input), & textarea {
    &:not([class^='uk-width-']) {
      width: calc(100% - 20px);
    }
    padding: 10px;
    outline: 0;
    border: 0;
    margin-bottom: 10px;
    font-size: $global-font-size;
    font-family: $global-font-family;
    transition-duration: .2s;

    &:last-of-type {
      margin-bottom: 20px;
    }

    &::placeholder {
      transition-duration: .2s;
      font-size: $global-font-size;
      font-family: $global-font-family;
    }
  }

  & button {

  }

  & a {
    margin-bottom: 10px;
    user-select: none;
  }
}

.lf-contribute-form {

}

.lf-contribute-form-choice {
  // @extend .uk-padding;
  // border: 3px solid $global-link-color;
}

.multiselect {
  margin-bottom: 10px;

  & .multiselect__tags {
    border-radius: 0;
    border: none;
    min-height: none !important;
    padding-left: 10px;
  }
  & .multiselect__content-wrapper {
    max-height: 200px !important;
    border: none !important;
    border-top: none !important;
    border-bottom: none !important;
    border-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  & .multiselect__tag {
    background: $global-link-color;
    margin-right: 5px;
    margin-bottom: 0;
    margin-top: 2px;
  }
  & .multiselect__tag-icon:after {
    color: white;
  }
  & .multiselect__tag-icon:hover,
  & .multiselect__tag-icon:focus {
    background: $global-link-color;
  }
  & .multiselect__single {
    margin-top: 2px;
    margin-bottom: 0 !important;
    padding-left: 0 !important;
  }
  & .multiselect__input {
    margin-top: 2px;
    margin-bottom: 0 !important;
    padding-left: 0 !important;
  }
  & .multiselect__option {
    &.multiselect__option--highlight {
      // background: white;
      background: $global-muted-background;
      color: inherit;
    }
    &.multiselect__option--selected {
      background: $global-muted-color;
      color: $global-muted-background;
    }
  }
}

.picture-input {
  & * {
    z-index: 0 !important;
  }

  & .preview-container {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  & .picture-preview {
    background-color: transparent !important;
  }

  & .picture-inner {
    border-radius: 2px !important;

    & .picture-inner-text {
      color: $global-muted-color;
    }
  }

  & .btn {
    display: none !important;
  }
}
