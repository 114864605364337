.lf-contribute-container {
  .lf-contribute-headline {
    & a {
      text-decoration: none;
    }

    @media (min-width: $breakpoint-medium) {
      margin-bottom: 0px;
    }
  }

  & .lf-contribute-description {
    @extend .uk-margin-medium-bottom;
  }

  @media (max-width: $breakpoint-small-max) {
    display: block;
    padding: 0 !important;
  }

  & .lf-contribute-image {
    @media (max-width: $breakpoint-small-max) {
      height: 220px;
    }
  }
}

.lf-floating-slide-in {
  @extend .mapboxgl-popup-content;
  background: white;
  width: auto !important;
  transition-duration: 0s;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  margin-left: 30px !important;
  transform: translateY(30px) translateZ(0);
  max-width: 335px !important;

  @media (max-width: $breakpoint-small-max) {
    // top: calc(100% - 60px);
  }
}

.lf-floating-slide-in.uk-active {
  position: fixed;
  visibility: visible;
  background: white;
  // top: 100vh;
  transform: translateY(0px) translateZ(0);
  top: initial !important;
  bottom: 30px;
  left: 0;
  opacity: 1;
  transition-duration: .4s;
  transition-property: transform opacity;
  z-index: 10000;

  @media (max-width: $breakpoint-small-max) {
    // top: calc(100% - 100px) !important;
  }
}

.lf-floating-slide-in + .uk-sticky-placeholder {
  height: 0 !important;
}

.lf-floating-slide-in-close {
  @extend .uk-float-right;
  @extend .uk-flex;
  @extend .uk-flex-center;
  @extend .uk-flex-middle;
  cursor: pointer;
  font-size: 1.7rem;
  width: 30px;
  height: 30px;
  background: $global-link-color;
  font-family: Karla;
  transform: translate(30px, -30px);
  color: $global-link-color !important;
  background: none;
}

// Mobile CTA

.lf-floating-slide-in-mobile {
  background: white;
  transition-duration: 0s;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  // width: calc(100% - 30px);
  // overflow: hidden;
  transform: translateY(30px) translateZ(0);
  border-top: 2px solid $global-link-color;

  @media (max-width: $breakpoint-small-max) {
    // top: calc(100% - 60px);
  }
}

.lf-floating-slide-in-mobile.uk-active {
  position: fixed;
  visibility: visible;
  background: white;
  // top: 100vh;
  transform: translateY(0px) translateZ(0);
  top: initial !important;
  bottom: 0;
  left: 0;
  opacity: 1;
  transition-duration: .4s;
  z-index: 10000;

  @media (max-width: $breakpoint-small-max) {
    // top: calc(100% - 100px) !important;
  }
}

.lf-floating-slide-in-mobile + .uk-sticky-placeholder {
  height: 0 !important;
}

.lf-floating-slide-in-close-mobile {
  @extend .uk-border-circle;
  @extend .uk-float-right;
  @extend .uk-flex;
  @extend .uk-flex-center;
  @extend .uk-flex-middle;
  @extend .uk-light;
  cursor: pointer;
  font-size: 1.7rem;
  width: 30px;
  height: 30px;
  background: $global-link-color;
  transform: translate(10px, -53px);
}

.lf-floating-slide-in-input {
  height: 14px;
  border: 1px solid #B3BDC3 !important;
  width: calc(100% - 22px) !important;
  font-size: 14px !important;

  &::placeholder {
    font-size: 14px !important;
  }
}

.lf-floating-slide-in-mobile-button {
  height: 36px;
  line-height: 30px;
  font-size: 14px;
}
