@charset "utf-8";
//
// Style manifest.
//

// 1. Config & helpers.
@import "abstracts/functions";
@import "abstracts/mixins";
@import "abstracts/placeholders";
@import "abstracts/animations";
@import "abstracts/vars.color";
@import "abstracts/vars.type";
@import "abstracts/vars.theme";


// 2. UIKit Core & Theme.
@import "theme/variables"; // needs to come before variables-theme
@import "~uikit/src/scss/variables-theme";
@import "~uikit/src/scss/mixins-theme";
@import "theme/mixins"; // needs to come before uikit-theme
@import "~uikit/src/scss/uikit-theme";


// 3. Vendor & plugin styles.
@import "vendor/vendor";


// 4. Custom components & style.
@import "custom/custom";
@import "streamfields/streamfields";
@import "transitions/transitions";


// 5. Utilities & helper classes.
@import "utilities/a11y";


// Shamefile. Throw quick fixes/hacks here for later refactoring.
@import "shame";


#app.app-loading {
  display: none;
}

.page {
  width: 100%;
  min-height: 200px; // To stop the footer from crashing into the header
}
