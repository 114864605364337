// Name:            Inverse
// Description:     Inverse component style for light or dark backgrounds
//
// Component:       `uk-light`
//                  `uk-dark`
//
// ========================================================================


// Variables
// ========================================================================

$inverse-global-color-mode:                      light !default;

$inverse-global-color:                           rgba($global-inverse-color, 1) !default;
$inverse-global-emphasis-color:                  $global-inverse-color !default;
$inverse-global-muted-color:                     rgba($global-inverse-color, 0.7) !default;
$inverse-global-inverse-color:                   $global-color !default;

$inverse-global-primary-background:              $global-inverse-color !default;
$inverse-global-muted-background:                rgba($global-inverse-color, 0.1) !default;

$inverse-global-border:                          rgba($global-inverse-color, 0.2) !default;
