.lf-story-card {
  transition-duration: .2s;
  position: relative;

  & .lf-story-card-content {
    @extend .uk-padding;
  }

  & .lf-story-card-date {
    @extend .uk-margin-small-bottom;
    @extend .uk-text-uppercase;
  }

  & .lf-card-tags-list {
    @extend .uk-margin-small-bottom;
  }

  & .lf-story-card-title {
    @extend .uk-text-large;
    transition-duration: .2s;
  }

  & .lf-story-card-description {
    & > p {
      @extend .uk-text-muted;
      transition-duration: .2s;
    }
  }

  & *:not(a) {
    color: initial;
  }

  &:hover {
    .lf-story-card-content {
      transition-duration: .2s;

      @media (min-width: $breakpoint-medium) {
        background: $lf--global-muted-hover-background;
      }
    }

    & .lf-story-card-title {
      color: $global-link-color;
    }

    & .lf-story-card-description {
      & > p {
        color: $global-color !important;
      }
    }
  }

  & a {
    text-decoration: none !important;
  }

  &:hover {
    text-decoration: none !important;
  }

  & .lf-story-card-title {
    @extend .lf-card-title;
    @extend .uk-margin-remove-top;
  }

  & p:last-of-type {
    @extend .uk-margin-remove-bottom;
  }
}

a.lf-story-card, a.lf-story-card:hover, a.lf-story-card *, a.lf-story-card:hover * {
  text-decoration: none;
}

.paginator-list-item:hover {
  & .lf-share-links.lf-share-links-hover {
    // display: flex;
  }
}

.lf-story-card:hover + .lf-share-links-timeline {
  & a {
    color: $global-link-color;
  }
}

.lf-story-card-content:hover + .lf-share-links-timeline {
  & a {
    color: $global-link-color;
  }
}

.lf-share-links-timeline {
  @extend .uk-flex;
  @extend .uk-flex-center;
  @extend .uk-flex-column;
  @extend .uk-padding;
  padding-right: 0;

  & a {
    color: $global-muted-background;
    padding: 5px;
    transition-duration: .2s;

    &:hover {
      color: $global-link-color;
    }
  }

  @media (max-width: $breakpoint-small-max) {
    display: block !important;
    padding: 30px;
    padding-top: 0;
    margin-top: -15px;
  }
}

.lf-share-links {
  display: inline-block;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 2px 0;
  z-index: 1;
  width: auto !important;

  &.uk-active {
    margin-right: 40px;
  }

  @media (max-width: $breakpoint-small-max) {
    display: none !important;
  }

  & a {
    color: $global-link-color !important;
  }

  & a span {
    line-height: 20px;
    height: 20px;

    & > svg {
      @extend .lf-icon-offset;
    }
  }

  & a:first-of-type span {
    @media (min-width: $breakpoint-medium) {
      border-right: 1px solid $global-muted-color;
      padding-right: 12px;
    }
    padding-right: 10px;
    padding-left: 10px;
  }

  & a:last-of-type span {
    padding-left: 12px;
    padding-right: 12px;
    margin-left: -6px;
  }

  &:hover + .lf-story-card {
    .lf-story-card-content {
      @media (min-width: $breakpoint-medium) {
        background: white;

        * {
          color: $global-link-hover-color;
        }
      }
    }
  }
}

.lf-share-links#share-links {
  @media (max-width: $breakpoint-small-max) {
    display: block !important;
  }
}

.lf-floating-share-links {
  top: 180px;
  width: auto !important;
  // transition-duration: 0s;
  visibility: hidden;
  right: 0;
  opacity: 0;
  position: absolute;
  transition-property: transform;
  transform: translateZ(0) translateY(0);

  @media (max-width: $breakpoint-small-max) {
    top: calc(100% - 60px);
  }
  & > .lf-share-links {
    display: flex !important;
    font-size: $global-font-size !important;
  }
}

.lf-floating-share-links.uk-active {
  position: fixed;
  visibility: visible;
  top: 180px !important;
  opacity: 1;
  transition-duration: .2s;
  transform: translateZ(0) translateY(-40px);

  @media (max-width: $breakpoint-small-max) {
    top: calc(100% - 60px) !important;
  }
}

.lf-floating-share-links + .uk-sticky-placeholder {
  height: 0 !important;
}

.lf-floating-link-to-top {
  @extend .uk-text-small;
  @extend .uk-text-uppercase;

  color: white;
  background: #1E00FF;
  width: 80px;
  margin-top: 30px;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  font-size: 11px;
  cursor: pointer;
  user-select: none;
}



.lf-twitter-story {
  & blockquote {
    font-size: inherit;
    font-style: normal;
    margin-bottom: 0;

    & p:last-of-type {
      @extend .uk-margin-small-bottom;
    }
  }
}

// .lf-image-story-container {
//   min-height: 250px;
// }
//
// .lf-image-caption {
//   background: rgba(0, 0, 0, 0.7);
//   color: white !important;
// }
//
// .lf-image-story-container.lf-expanded {
//   height: 400px;
//
//   @media (min-width: $breakpoint-small) {
//     height: 550px;
//   }
// }
//
// .lf-video-embed {
//   min-height: 250px;
//   background: black;
// }
//
// .lf-video-embed.lf-expanded {
//   min-height: 400px;
//   @media (min-width: $breakpoint-small) {
//     min-height: 550px;
//   }
// }

.lf-image-story-container {
  min-height: 250px;

  @media (max-width: $breakpoint-xsmall-max) {
    min-height: 200px;
  }
}

.lf-image-caption {
  background: rgba(0, 0, 0, 0.6);
  color: white !important;
}

.lf-image-story-container.lf-expanded {
  // height: 350px;
  padding-bottom: 55.25%;

  @media (min-width: $breakpoint-small) {
    // height: 460px;
  }
}

.lf-video-embed {
  min-height: 250px;
  background: black;

  @media (max-width: $breakpoint-xsmall-max) {
    min-height: 200px;
  }
}

.lf-video-embed.lf-expanded {
  min-height: 350px;
  // padding-bottom: 55.25%;

  @media (min-width: $breakpoint-small) {
    min-height: 460px;
  }
}

.lf-video-collapse {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px !important;
  height: 30px;
  border: 1px solid $global-link-color;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & .uk-icon > svg {
    color: $global-link-color;
  }
}

.lf-image-collapse {
  @extend .lf-video-collapse;
  display: none;
}

.lf-image-container:hover .lf-image-collapse {
  display: flex;
}

.lf-image-collapse.lf-image-collapse-expanded {
  display: flex;
}

.lf-video-container {
  .lf-video-overlay {
    background: linear-gradient(to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5));

    & > span {
      color: $global-link-color !important;
      background: white;

      & > svg {
        transform: scaleY(.75);
      }
    }
  }
}

.lf-paginator-container {
  @media (max-width: $breakpoint-small-max) {
    padding: 0 !important;
  }
}
