.uk-navbar-left {
  flex-shrink: 0;
}

// .uk-navbar-container + .uk-sticky-placeholder {
//   height: 200px !important;
// }

.uk-navbar-container {
  transition-duration: 0s;
}

.uk-navbar-container.lf-navbar-active {
  border-bottom: 1px solid $global-muted-background;
  height: 100px;

  @media (max-width: $breakpoint-small-max) {
    height: 82px;
  }

  & .uk-navbar-item {
    height: 100px;

    @media (max-width: $breakpoint-small-max) {
      height: 82px;
    }
  }

  .uk-logo {
    width: 200px;
    margin-right: 50px;

    @media (max-width: $breakpoint-small-max) {
      width: 180px;
    }
    @media (max-width: $breakpoint-xsmall-max) {
      width: 150px;
    }
  }
}

.uk-navbar-item {
  height: 100px;

  @media (max-width: $breakpoint-small-max) {
    height: 82px;
  }
}

.lf-navbar-share-twitter {
  & .uk-icon {
    color: black;
  }
}
.lf-navbar-share-facebook {
  & .uk-icon {
    color: black;
  }
}

.lf-navbar-inactive .uk-navbar-nav,
body:not([data-page-model="pagesStoryPage"]):not([data-page-model="pagesTextStoryPage"]):not([data-page-model="pagesGalleryStoryPage"]) .uk-navbar-nav {
  & .lf-navbar-share-twitter {
    display: none;
  }
  & .lf-navbar-share-facebook {
    display: none;
  }
}

body[data-page-model="pagesStoryPage"] .lf-navbar-active .uk-navbar-nav,
body[data-page-model="pagesTextStoryPage"] .lf-navbar-active .uk-navbar-nav,
body[data-page-model="pagesGalleryStoryPage"] .lf-navbar-active .uk-navbar-nav {
  & .lf-navbar-tags {
    display: none;
  }
  & .lf-navbar-search {
    display: none;
  }
  & .lf-navbar-user {
    display: none;
  }
}

.uk-navbar-nav > li {
  &:first-of-type {
    & > a > .uk-icon {
      width: 22px;
      height: 22px;
    }
  }

  @media (max-width: $breakpoint-small-max) {
    & > a {
      padding: 0 10px;
    }

    & > a > .uk-icon,
    & > a > .uk-icon > svg {
      width: 15px;
      height: 15px;
    }

    &:first-of-type {
      & > a > .uk-icon,
      & > a > .uk-icon > svg {
        width: 17px;
        height: 17px;
      }
    }
  }
}

.lf-menu-tag {
  margin-right: 15px;
  line-height: 1;

  @media (min-width: $breakpoint-xlarge) {
    & > .clip-button {
      padding: 0 15px;
    }
  }

  & > a {
    padding: 10px 5px;
  }
  & > a:first-of-type {
    padding: 10px 0 10px 0;
  }
}

.lf-menu-tag:not(:last-child)::after {
  content: "/";
  padding-left: 15px;
  font-size: $global-small-font-size;
}

.lf-mobile-nav-toggle {
}

.lf-nav-dropdown {
  background: white;
  box-shadow: none;
  margin-top: 0;
  min-width: 147px;
}

.lf-header {
  // @extend .uk-padding;
  // @extend .uk-padding-remove-horizontal;
  padding: 20px 0;

  @media (max-width: $breakpoint-small-max) {
    padding: 0 !important;
  }
}

.uk-navbar-container:not(.lf-navbar-active) {
  transition-duration: 0s;

  @media (max-width: $breakpoint-small-max) {
    transition-duration: .2s;
  }
}

.uk-logo {
  width: 250px;
  transition-duration: .2s;
  // transition-timing-function: linear;

  @media (max-width: $breakpoint-medium-max) {
    width: 180px;
  }
  @media (max-width: $breakpoint-xsmall-max) {
    width: 150px;
  }
}

.lf-tag-menu {

}

.lf-tag-menu-item {
  padding: 5px 0;

  & a {
    transition-duration: .2s;
  }
  & a:hover {
    text-decoration: none;
  }
}

.lf-footer-link:hover {
  text-decoration: none;
}

.lf-footer-flex {
  @media (max-width: $breakpoint-small-max) {
    display: block;

    & span {
      display: block;
    }

    & .lf-footer-link {
      &:first-of-type {
        margin-left: 0 !important;
      }
    }
  }
}

.lf-menu-tag-list {
  @media (max-width: 1100px) {
    display: none !important;
  }
}

.lf-menu-title {
}

.lf-menu-intro {
  color: #97A1A7;
  line-height: 1;
  margin-bottom: 2px;
}

.lf-larger-icon {
  width: 25px;
  height: 25px;

  @media (max-width: $breakpoint-small-max) {
    width: 20px;
    height: 20px;
  }
}

.lf-menu-contact {
  & p {
    margin: 0px;
  }

  & p:empty {
    margin-bottom: 30px;
  }

  .rich-text {
    margin-bottom: 50px;
  }
}

.lf-header {
  z-index: 100;

  .uk-header {
    // transition: $transition-duration;
  }

  .uk-header-item {
    // height: auto;
  }

  .lf-header-leftonxs {
    @media (max-width: $breakpoint-xsmall-max) {
      position: static;
      top: 0;
      left: 0;
      transform: none;
      margin-left: 15px;
    }
  }
}

.lf-header-top {
  // border-bottom: 1px solid rgba($c-smoke, 0.15);
  p {
    // font-size: 14px;
  }

  .uk-subnav > * > :first-child {
    // font-size: 14px;
  }
}

.lf-header-util {
  padding-top: 10px;

  &.uk-header-item {
    height: auto;
  }
}

.lf-nav-right {
  @extend .uk-width-expand;
  margin: 0 !important;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $breakpoint-medium-max) {
    justify-content: flex-end;
  }
}

.bar {
  z-index: 1000;
  top: 95px !important;
  box-shadow: none !important;
  height: 4px !important;
  background-color: $global-link-color !important;

  @media (max-width: $breakpoint-small-max) {
    top: 77px !important;
  }
}

.lf-menu-story-content {
  width: 483px;
  line-height: 1.2;
}

.lf-menu-times {
  width: 36px;
  height: 58px;
  position: absolute;
  top: 0;
  right: 0;
}

@media(max-width: 1370px) {
  .lf-menu-times {
    right: -36px;
  }
}

@media(max-width: 959px) {
  .lf-menu-times {
    top: -20px;

    > div {
      font-size: 2rem !important;
    }
  }
}

@media(max-width: 639px) {
  .lf-menu-times {
    top: 14px;
    right: -21px;

    > div {
      font-size: 2rem !important;
    }
  }
}
