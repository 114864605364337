// Name:            Notification
// Description:     Component to create notification messages
//
// Component:       `uk-notification`
//
// Sub-objects:     `uk-notification-message`
//
// Adopted:         `uk-notification-close`
//
// Modifiers:       `uk-notification-top-center`
//                  `uk-notification-top-right`
//                  `uk-notification-bottom-left`
//                  `uk-notification-bottom-center`
//                  `uk-notification-bottom-right`
//                  `uk-notification-message-primary`
//                  `uk-notification-message-success`
//                  `uk-notification-message-warning`
//                  `uk-notification-message-danger`
//
// ========================================================================


// Variables
// ========================================================================

$notification-position:                                10px !default;
$notification-z-index:                                 $global-z-index + 40 !default;
$notification-width:                                   350px !default;

$notification-message-margin-bottom:                   10px !default;
$notification-message-padding:                         $global-small-gutter !default;
$notification-message-background:                      $global-muted-background !default;
$notification-message-color:                           $global-color !default;
$notification-message-font-size:                       $global-medium-font-size !default;
$notification-message-line-height:                     1.4 !default;

$notification-close-top:                               $notification-message-padding + 5px !default;
$notification-close-right:                             $notification-message-padding !default;

$notification-message-primary-color:                   $global-primary-background !default;
$notification-message-success-color:                   $global-success-background !default;
$notification-message-warning-color:                   $global-warning-background !default;
$notification-message-danger-color:                    $global-danger-background !default;
