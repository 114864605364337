.lf-vessel-detail-grid-item {
  padding-bottom: 15px;
  margin-right: 15px;
  border-bottom: 1px solid rgba(255,255,255,0.2);
}

.uk-section-primary .lf-vessel-detail-grid-item .uk-text-small.uk-text-muted.uk-text-uppercase {
  color: rgba(255, 255, 255, .5) !important;
}

.lf-vessel-detail-dropdown {
  @extend .uk-text-medium;
  @extend .uk-position-z-index;

  // border: 2px solid $global-link-color;
  border-bottom: none;
  // font-weight: bold;
  text-decoration: none !important;
  white-space: nowrap;

  @media (max-width: $breakpoint-medium-max) {
    font-size: 1rem;
  }

  &:first-of-type {
    @media (min-width: $breakpoint-medium) {
      border-left: none;
      border-right: none;
    }
  }
}

.lf-vessel-detail-cover-photo-container {
  height: 411px;
}

.lf-vessel-detail-cover-photo {
  height: 411px;
  width: calc(50% + 30px);
  transform: translateX(-30px);
}

.lf-vessel-detail.uk-child-width-1-2 > div:last-child,
.lf-vessel-detail.uk-child-width-1-2 > div.uk-first-column:nth-last-child(2) {
  & .lf-vessel-detail-grid-item {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.lf-vessel-detail-specifics > div:last-child {
  & .lf-vessel-detail-grid-item {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.lf-vessel-dropdown {
  @extend .lf-nav-dropdown;
  padding: 30px;

  & li > a {
    text-decoration: none !important;
  }

  & li:not(*:last-child) {
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    margin-bottom: 15px;
  }
}

.lf-vessel-specifications-list {
  @extend .uk-padding;
  @extend .uk-padding-remove-bottom;

  @media (min-width: $breakpoint-medium) {
    padding-right: 0;
  }
  @media (max-width: $breakpoint-small-max) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.lf-vessel-detail-value {
  font-size: .88em;
}

.lf-vessel-container {
  border-top: 2px solid #EDF3F6;
  padding-top: 60px;
  padding-bottom: 60px;

  &:last-child {
    border-bottom: 2px solid #EDF3F6;
  }

  @media (max-width: $breakpoint-small-max) {
    border-left: none !important;
    border-right: none !important;
  }
}

.lf-vessel-container-no-border {
  border-top: 0;
}

.lf-vessel-container-noborder-bottom,
.lf-vessel-container-noborder-bottom:last-child {
  border-bottom: 0;
}

.lf-vessel-heading {
  @extend .uk-padding-small;
  @extend .uk-padding-remove-vertical;

  margin-bottom: 40px;
  padding-left: 0;
  padding-right: 0;

  @media (min-width: $breakpoint-medium) {
    padding: 0 !important;
  }
}

.lf-vessel-detail-value {
  & * {
    margin: 0 !important;
  }

  & ul, & ol {
    padding-left: 15px;
  }
}

#interactive_map {
  iframe {
    width: 100% !important;
  }
}
