.lf-dropcap {
  @extend .uk-h1;
  float: left;
  font-size: 95px;
  padding-right: 5px;
  margin-bottom: 0px;
  line-height: 75px;
  text-transform: uppercase;
  @media (min-width: $breakpoint-medium) {
    font-size: 115px;
    padding-right: 10px;
    line-height: 90px;
  }
}

.block-dropcap + .block-text > .lf-richtext > .rich-text > p:first-child::first-letter {
  font-size: 0px;
}

.lf-story-cover {
  // height: 350px;
  padding-bottom: 55.25%;

  @media (max-width: $breakpoint-xsmall-max) {
    // height: 225px;
  }
}

// .lf-story-cover.lf-featured-story-cover {
// }

.lf-collapsing-container {
  @extend .uk-container;
  @extend .uk-container-large;

  @media (max-width: $breakpoint-small-max) {
    padding: 0;
  }
}

.lf-collapsing-container-small {
  @extend .uk-container;
  @extend .uk-container-small;

  @media (max-width: $breakpoint-small-max) {
    padding: 0;
  }
}

.lf-collapsing-container\@s {
  @extend .uk-container;
  @extend .uk-container-large;

  @media (max-width: $breakpoint-xsmall-max) {
    padding: 0;
  }
}

.lf-pad-on-collapse {
  @media (max-width: $breakpoint-small-max) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.lf-pad-on-collapse-large {
  @media (max-width: $breakpoint-small-max) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.lf-pad-on-collapse\@s {
  @media (max-width: $breakpoint-xsmall-max) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.lf-author-profile {
  @extend .uk-visible\@m;
  @extend .uk-padding;
  @extend .uk-padding-remove-horizontal;

  @media (min-width: $breakpoint-medium) {
    padding-top: 80px;
    padding-bottom: 0;
  }

  & > div > a {
    text-decoration: none;
  }
}

.lf-next-stories {
  & a {
    text-decoration: none;
  }
}

.lf-story-tags-list {
  padding-right: 0;
  min-height: 29px;
  margin-bottom: 0;

  @media (max-width: $breakpoint-small-max) {
    margin: 30px 0 0;
  }
}

.lf-story-title {
  @media (max-width: $breakpoint-small-max) {
    margin-bottom: 15px !important;
  }
}

.lf-story-media-container {
  @extend .uk-padding;
  height: 50vh;
  min-height: 350px;

  @media (max-width: $breakpoint-small-max) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
  }
}

.lf-default-avatar {
  background: $global-muted-background;
  border-radius: 500px;
  padding: 4px;
}

.lf-author-profile .lf-default-avatar,
.lf-author-profile .lf-avatar {
  height: 45px;
  width: 45px;
}

.lf-tag-detail .lf-default-avatar,
.lf-tag-detail .lf-avatar {
  height: 86px;
  width: 86px;
}

.uk-section-muted .lf-default-avatar {
  background: white;
}

.lf-slideshow {
  min-height: 510px !important;
  height: 50vh !important;
}

.lf-slideshow img {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
}

.instagram-media {
  max-width: 100% !important;
}

.lf-content-image-small {
  max-width: 720px;
  margin: auto;
}

.lf-story-banner-container {
  & hr {
    max-width: 180px;
    border-color: $global-muted-background;

    @media (min-width: $breakpoint-medium) {
      margin: 20px auto;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.lf-story-meta {
  & a {
    text-decoration: none !important;
    color: $global-color;
  }
}

.lf-story-meta-label {
  // Change request by client to make this grey. (#777).
  // This effectively removes the need for .lf-story-meta-label-grey, but it's kept for legacy purposes.
  // color: $global-link-color;
  color: #777;
}

.lf-story-meta-label-grey {
  color: #777;
}

.lf-content-pullquote {
  border-left: 3px solid $global-link-color;
}

.container-authors {
  $author-img-size: 62px;

  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #ccc;

  .author-block {
    position: relative;
  }

  .container-author-img {
    position: absolute;
    top: 0;
    left: 0;
  }

  .author-img {
    width: $author-img-size;
    height: $author-img-size;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
  }

  .author-content {

    padding-left: calc(#{$author-img-size} + 20px);
  }
}
