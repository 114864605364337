.uk-height-xlarge {
  height: 550px;
}

.uk-text-medium {
  font-size: 1.2rem;
}

.lf-icon-offset {
  position: relative;
  top: -1px;
}

.uk-height-1-1 {
  height: 100% !important;
}

.uk-max-height-1-1 {
  max-height: 100% !important;
}

.lf-remove-bottom-margin {
  p:last-child {
    margin-bottom: 0;
  }
}

.lf-remove-decoration {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.lf-padding-remove\@s {
  @media (max-width: $breakpoint-small-max) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
