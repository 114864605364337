.lf-instagram-iframe-container {
  position: relative;
  overflow: hidden;
  max-width: 720px;
  height: 950px;
  margin: auto;

  &.uk-float-left, &.uk-float-right {
    height: 740px;

    @media (max-width: $breakpoint-medium-max) {
      height: 700px;
    }

    @media (max-width: $breakpoint-small-max) {
      float: none !important;
      margin: auto !important;
      height: 940px;
    }

    @media (max-width: $breakpoint-xsmall-max) {
      max-width: 380px;
      height: 645px;
    }
  }

  @media (max-width: $breakpoint-small-max) {
    height: 940px;
  }

  @media (max-width: $breakpoint-xsmall-max) {
    max-width: 380px;
    height: 645px;
  }

  & > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 720px;
    height: 950px;

    @media (max-width: $breakpoint-small-max) {
      height: 940px;
    }

    @media (max-width: $breakpoint-xsmall-max) {
      height: 645px;
    }
  }
}

.instagram-media {
  max-width: none !important;
}

.lf-twitter-iframe-container {
  position: relative;
  overflow: hidden;
  max-width: 516px;
  height: 265px;
  margin: auto;

  @media (max-width: $breakpoint-xsmall-max) {
    max-width: 100%;
    height: 265px;
  }

  & > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 680px;

    @media (max-width: $breakpoint-xsmall-max) {
      height: 680px;
    }
  }
}
