body {
  &.is-firstrun {
    .intro {
      opacity: 1;
    }
  }

  #intro-progress-box {
    width: 0;
    transition-duration: 2s;
    overflow: hidden;
  }

  #intro-progress {
    max-width: inherit;
  }

  &.pace-running {
    #intro-progress-box {
      width: 80%;
    }
  }

  &.pace-done {
    .intro {
      opacity: 0;
    }

    #intro-progress-box {
      width: 100%;
    }
  }

  &:not(.is-firstrun) {
    .intro {
      opacity: 0;
    }
  }

  &.no-js {
    .intro {
      display: none;
    }
  }
}

.intro {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 100001;
  pointer-events: none;
  user-select: none;
  transition: 1s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.intro-spinner {
  animation: pulseIntro 3s forwards;
  position: relative;

  & svg {
    width: 120px;
    height: 120px;
  }
}

@keyframes pulseIntro {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

@keyframes animationStart {
  0% {
    width: 0;
  }
  100% {
    width: 30%;
  }
}
