// Name:            Modal
// Description:     Component to create modal dialogs
//
// Component:       `uk-modal`
//
// Sub-objects:     `uk-modal-page`
//                  `uk-modal-dialog`
//                  `uk-modal-header`
//                  `uk-modal-body`
//                  `uk-modal-footer`
//                  `uk-modal-title`
//                  `uk-modal-close`
//
// Adopted:         `uk-modal-close-default`
//                  `uk-modal-close-outside`
//                  `uk-modal-close-full`
//
// Modifiers:       `uk-modal-container`
//                  `uk-modal-full`
//
// States:          `uk-open`
//
// ========================================================================


// Variables
// ========================================================================

$modal-z-index:                                  $global-z-index + 10 !default;
$modal-background:                               rgba(3,34,54,0.91) !default;

$modal-padding-horizontal:                       15px !default;
$modal-padding-horizontal-s:                     $global-gutter !default;
$modal-padding-horizontal-m:                     $global-medium-gutter !default;
$modal-padding-vertical:                         $modal-padding-horizontal !default;
$modal-padding-vertical-s:                       50px !default;

$modal-dialog-width:                             600px !default;
$modal-dialog-background:                        $global-background !default;

$modal-container-width:                          1200px !default;

$modal-body-padding-horizontal:                  $global-gutter !default;
$modal-body-padding-vertical:                    $global-gutter !default;

$modal-header-padding-horizontal:                $global-gutter !default;
$modal-header-padding-vertical:                  ($modal-header-padding-horizontal / 2) !default;
$modal-header-background:                        $global-muted-background !default;

$modal-footer-padding-horizontal:                $global-gutter !default;
$modal-footer-padding-vertical:                  ($modal-footer-padding-horizontal / 2) !default;
$modal-footer-background:                        $global-muted-background !default;

$modal-title-font-size:                          $global-xlarge-font-size !default;
$modal-title-line-height:                        1.3 !default;

$modal-close-position:                           $global-small-margin !default;
$modal-close-padding:                            5px !default;

$modal-close-outside-position:                   0 !default;
$modal-close-outside-translate:                  100% !default;
$modal-close-outside-color:                      lighten($global-inverse-color, 20%) !default;
$modal-close-outside-hover-color:                $global-inverse-color !default;
