// Name:            Overlay
// Description:     Component to create content areas overlaying an image
//
// Component:       `uk-overlay`
//
// Adopted:         `uk-overlay-icon`
//
// Modifier:        `uk-overlay-default`
//                  `uk-overlay-primary`
//
// ========================================================================


// Variables
// ========================================================================

$overlay-padding-horizontal:                     $global-gutter !default;
$overlay-padding-vertical:                       $global-gutter !default;

$overlay-default-background:                     rgba($global-background, 0.8) !default;

$overlay-primary-background:                     rgba($global-secondary-background, 0.8) !default;
$overlay-primary-color-mode:                     light !default;

