.lf-tag-detail {
  @media (max-width: $breakpoint-small-max) {
    display: block;
  }
}

.lf-tag-detail-title {
  flex-shrink: 0;
}

.lf-tag-detail-description {
  @extend .lf-card-title;
  @extend .uk-text-medium;
  line-height: 1.25;

  & .rich-text p:last-child {
    margin-bottom: 0;
  }

  @media (max-width: $breakpoint-small-max) {
    margin-left: 0 !important;
    display: block;
    margin-top: 15px;
    font-size: 1rem;
    line-height: 1.6;
  }
}
