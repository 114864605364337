// Name:            Progress
// Description:     Component to create progress bars
//
// Component:       `uk-progress`
//
// ========================================================================


// Variables
// ========================================================================

$progress-height:                                15px !default;
$progress-margin-vertical:                       $global-margin !default;
$progress-background:                            $global-muted-background !default;

$progress-bar-background:                        $global-primary-background !default;
