// Name:            Width
// Description:     Utilities for widths
//
// Component:       `uk-child-width-*`
//                  `uk-width-*`
//
//
// ========================================================================


// Variables
// ========================================================================

$width-small-width:                              150px !default;
$width-medium-width:                             300px !default;
$width-large-width:                              450px !default;
$width-xlarge-width:                             600px !default;
$width-xxlarge-width:                            750px !default;

