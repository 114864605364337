//
// Typography settings.
//

//
// Font stacks.
@import url('https://fonts.googleapis.com/css?family=Karla:400,400i,700,700i');
@import url("https://use.typekit.net/rac6jlx.css");

$fontstack-serif:    'Bookmania', Georgia, "Times", "Times New Roman", serif;
$fontstack-custom:   'Karla', sans-serif;
$fontstack-mono:     hack, "Consolas", "Liberation Mono", "Courier", monospace;
