.lf-card-border {
  @extend .uk-padding-small;
  @extend .uk-padding-remove-vertical;
  @extend .uk-padding-remove-right;
  border-left: 3px solid $global-primary-background;
}

.lf-card-tags-list {
  margin-bottom: 10px;
  line-height: 1;
}

.lf-card-tag {
  @extend .uk-text-small;
  @extend .uk-text-uppercase;
  @extend .uk-display-inline-block;
  text-decoration: none !important;
}

.lf-card-tag:not(:last-child)::after {
  content: "/";
  padding-left: 15px;
  font-size: $global-small-font-size;
  color: $global-color;
  margin-right: 15px;
}

.uk-text-muted .lf-card-tag:not(:last-child)::after {
  color: $global-muted-color;
}

.lf-featured-card.lf-card-meta {
  .lf-card-title {
    @extend .uk-padding-remove;
    border-left: none;
  }

  .lf-card-description {
    @media (max-width: $breakpoint-small-max) {
      padding: 0 !important;
    }

    & p:first-child {
      margin-top: 0;
    }

    & p:last-child {
      margin-bottom: 0;
    }
  }
}

.lf-featured-card {
  margin-top: 30px;
  
  @media (max-width: $breakpoint-small-max) {
    margin-top: 0 !important;
  }
}

.lf-featured-story-cover {
  // height: 60vh;
  // min-height: 350px;
  padding-bottom: 56.25%;

  @media (max-width: $breakpoint-medium-max) {
    // height: 55vh;
  }

  @media (max-width: $breakpoint-small-max) {
    // height: 50vh;
  }

  @media (max-width: $breakpoint-xsmall-max) {

  }
}

.lf-card-meta {
  & a {
    text-decoration: none !important;
  }
}

a:hover > .lf-card-title {
  text-decoration: none !important;
  color: $global-link-hover-color;
}
