// Name:            Position
// Description:     Utilities to position content
//
// Component:       `uk-position-absolute`
//                  `uk-position-relative`
//                  `uk-position-z-index`
//                  `uk-position-top`
//                  `uk-position-bottom`
//                  `uk-position-left`
//                  `uk-position-right`
//                  `uk-position-top-left`
//                  `uk-position-top-center`
//                  `uk-position-top-right`
//                  `uk-position-bottom-left`
//                  `uk-position-bottom-center`
//                  `uk-position-bottom-right`
//                  `uk-position-center`
//                  `uk-position-center-left`
//                  `uk-position-center-right`
//                  `uk-position-cover`
//
// Modifiers:       `uk-position-small`
//                  `uk-position-medium`
//                  `uk-position-large`
//
// ========================================================================


// Variables
// ========================================================================

$position-small-margin:                                 $global-small-gutter !default;
$position-medium-margin:                                $global-gutter !default;
$position-large-margin:                                 $global-gutter !default;
$position-large-margin-l:                               50px !default;

