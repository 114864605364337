.lf-scrolllist-content {
  min-height: 100vh;
  padding-top: 10vh;
  padding-bottom: 10vh; 
}

.lf-scrolllist-image {
  position: relative;
  margin: 0 auto;
  padding: 10vh 0;
  height: 80vh;
  text-align: center;
}

.lf-scrolllist-img {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transition: 0.3s;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  
  &.is-first.is-top.is-outside {
    opacity: 1;
  }
  
  &.is-inside.is-bottom,
  &.is-inside.is-top {
    opacity: 1;
  }
  
  &.is-bottom.is-outside,
  &.is-top.is-outside {
    opacity: 0;
  }
  
  &.is-last.is-bottom.is-outside {
    opacity: 1;
  }
}

.lf-scrolllist--mobile {
  .slick-arrow {
    display: none !important;
  }

  .slick-dots {
    position: absolute;
    top: 450px;
    padding: 0;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    width: 100%;
    list-style: none;

    li {
      margin: 0 4px;
      padding: 0;

      button {
        margin: 0;
        padding: 0;
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: transparent;
        text-indent: 100%;
        overflow: hidden;
        white-space: nowrap;
        border: 1px solid rgba(102, 102, 102, 0.4);
        transition: 0.2s ease-in-out;
        transition-property: background-color, border-color;
      }

      &.slick-active {
        button {
          background-color: rgba(102, 102, 102, 0.6);
          border-color: transparent;
        }
      }
    }
  }
}