// Name:            Badge
// Description:     Component to create notification badges

// Component:       `uk-badge`
//
// ========================================================================

// @mixin hook-badge(){}
// @mixin hook-badge-hover(){}
// @mixin hook-badge-misc(){}


// Inverse
// ========================================================================

// @mixin hook-inverse-badge(){}
// @mixin hook-inverse-badge-hover(){}

@mixin hook-badge() {
  background-color: transparent;
  border: 1px solid $global-link-color;
  color: $global-link-color;
}

@mixin hook-badge-hover() {
  border: 1px solid $global-link-hover-color;
  color: $global-link-hover-color;
}

@mixin hook-inverse-badge(){
  background-color: transparent;
  border: 1px solid $global-background;
  color: $global-background;
}

@mixin hook-inverse-badge-hover(){
  background-color: transparent;
  border: 1px solid $global-background;
  color: $global-background;
}
