// Name:            Text
// Description:     Utilities for text
//
// Component:       `uk-text-*`
//
// ========================================================================


// @mixin hook-text-lead(){}
// @mixin hook-text-meta(){}
// @mixin hook-text-small(){}
// @mixin hook-text-large(){}
// @mixin hook-text-background(){}
// @mixin hook-text-misc(){}

@mixin hook-text-small(){
  letter-spacing: .5px;
}

// Inverse
// ========================================================================


// @mixin hook-inverse-text-lead(){}
// @mixin hook-inverse-text-meta(){}
