// Name:            Dotnav
// Description:     Component to create dot navigations
//
// Component:       `uk-dotnav`
//
// Modifier:        `uk-dotnav-vertical`
//
// States:          `uk-active`
//
// ========================================================================


// Variables
// ========================================================================

$dotnav-margin-horizontal:                       12px !default;
$dotnav-margin-vertical:                         $dotnav-margin-horizontal !default;

$dotnav-item-width:                              10px !default;
$dotnav-item-height:                             $dotnav-item-width !default;
$dotnav-item-border-radius:                      50% !default;

$dotnav-item-background:                         rgba($global-color, 0.2) !default;
$dotnav-item-hover-background:                   rgba($global-color, 0.6) !default;
$dotnav-item-onclick-background:                 rgba($global-color, 0.2) !default;
$dotnav-item-active-background:                  rgba($global-color, 0.6) !default;


// Inverse
// ========================================================================

$inverse-dotnav-item-background:                rgba($inverse-global-color, 0.5) !default;
$inverse-dotnav-item-hover-background:          rgba($inverse-global-color, 0.9) !default;
$inverse-dotnav-item-onclick-background:        rgba($inverse-global-color, 0.5) !default;
$inverse-dotnav-item-active-background:         rgba($inverse-global-color, 0.9) !default;
