//
// Image Story subsections.
.lf-imagestory {
  box-sizing: border-box;

  * {
    box-sizing: inherit;
  }
}

.lf-imagestory__content {
  > :last-child {
    margin-bottom: 0;
  }
}

.lf-imagestory--triple-up {
  .lf-imagestory__visuals {
    @media (max-width: $breakpoint-xsmall-max) {
      margin-right: 40px !important;
      margin-left: 40px;
    }

    @media (min-width: $breakpoint-small) {
      padding-left: 40px;
    }

    @media (min-width: $breakpoint-medium) {
      padding-left: 70px;
    }
  }

  .lf-imagestory__bug {
    top: 60%;
    left: -10%;
    z-index: -1;
    width: 181px;
    height: 212px;

    // @media (max-width: $breakpoint-xsmall-max) {
    //   display: none;
    // }

    @media (min-width: $breakpoint-medium) {
      width: 242px;
      height: 283px;
    }
  }

  .lf-imagestory__secondary {
    @media (max-width: $breakpoint-small-max) {
      width: 244px;
      height: 300px;
    }

    @media (max-width: $breakpoint-xsmall-max) {
      display: none;
    }
  }
}

.lf-imagestory--triple-right {
  .lf-imagestory__visuals {
    padding-left: 40px;

    // @media (max-width: $breakpoint-small-max) {
    //   margin-right: 40px;
    //   margin-left: 0 !important;
    // }

    .lf-imagestory__secondary {
      right: 40px;
      bottom: -100px;

      @media (max-width: $breakpoint-small-max) {
        width: 195px;
        height: 229px;
      }
    }
  }

  .lf-imagestory__bug {
    width: 181px;
    height: 212px;
    top: 25%;
    right: 10%;
    z-index: -1;
  }

  .lf-imagestory__content {
    @media (min-width: $breakpoint-medium) {
    }
  }
}

.lf-imagestory--single {
  .lf-imagestory__visuals {
    @media (max-width: $breakpoint-xsmall-max) {
      margin-right: 40px;
      margin-bottom: 40px !important;
      margin-left: 40px;
    }
  }

  .lf-imagestory__content {
    // font-size: 1.375rem;
    // line-height: 1.6;

    @media (min-width: $breakpoint-small) {
      padding-right: 70px;
      padding-left: 40px;
    }

    @media (min-width: $breakpoint-medium) {
      padding-right: 100px;
      padding-left: 70px;
    }
  }
}

//
// Make SVGs responsive.
//
.lf-svg {
  max-width: 100%;
  height: 100%; // Make Safari:iOS scale properly.
}


//
// Line motif element.
//
.lf-line {
  position: relative;

  &::before {
    position: absolute;
    top: 50%;
    right: 100%;
    width: 125px;
    height: 1px;
    margin-right: 25px;
    content: "";
    background-color: color(black);
  }

  &.lf-line--switch {
    &::before {
      right: auto;
      left: 100%;
      margin-right: 0;
      margin-left: 25px;
    }
  }

  &.lf-line--full {
    &::before {
      width: 100%;
    }
  }

  &.lf-line--1-1 {
    &::before {
      top: 0.55em;
    }
  }

  &.lf-line--1-2 {
    &::before {
      top: 0.6em;
    }
  }

  &.lf-line--1-3 {
    &::before {
      top: 0.65em;
    }
  }

  &.lf-line--1-4 {
    &::before {
      top: 0.7em;
    }
  }

  &.lf-line--1-5 {
    &::before {
      top: 0.75em;
    }
  }
}
