// Name:            Divider
// Description:     Styles for dividers
//
// Component:       `uk-divider-icon`
//                  `uk-divider-small`
//
// ========================================================================


// Variables
// ========================================================================

$divider-margin-vertical:                        $global-margin !default;

$divider-icon-width:                             50px !default;
$divider-icon-height:                            20px !default;
$divider-icon-color:                             $global-border !default;
$divider-icon-line-top:                          50% !default;
$divider-icon-line-width:                        100% !default;
$divider-icon-line-border-width:                 $global-border-width !default;
$divider-icon-line-border:                       $global-border !default;

$internal-divider-icon-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22none%22%20stroke%3D%22#000%22%20stroke-width%3D%222%22%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%227%22%3E%3C%2Fcircle%3E%0A%3C%2Fsvg%3E%0A" !default;

$divider-small-width:                            100px !default;
$divider-small-border-width:                     $global-border-width !default;
$divider-small-border:                           $global-border !default;


// Inverse
// ========================================================================

$inverse-divider-icon-color:                       $inverse-global-border !default;
$inverse-divider-icon-line-border:                 $inverse-global-border !default;
$inverse-divider-small-border:                     $inverse-global-border !default;
