// Name:            Tile
// Description:     Component to create tiled boxes
//
// Component:       `uk-tile`
//
// Modifiers:       `uk-tile-xsmall`
//                  `uk-tile-small`
//                  `uk-tile-large`
//                  `uk-tile-xlarge`
//                  `uk-tile-default`
//                  `uk-tile-muted`
//                  `uk-tile-primary`
//                  `uk-tile-secondary`
//
// States:          `uk-preserve-color`
//
// ========================================================================


// Variables
// ========================================================================

$tile-padding-horizontal:                       15px !default;
$tile-padding-horizontal-s:                     $global-gutter !default;
$tile-padding-horizontal-m:                     $global-medium-gutter !default;
$tile-padding-vertical:                         $global-medium-margin !default;
$tile-padding-vertical-m:                       $global-large-margin !default;

$tile-xsmall-padding-vertical:                  $global-margin !default;

$tile-small-padding-vertical:                   $global-medium-margin !default;

$tile-large-padding-vertical:                   $global-large-margin !default;
$tile-large-padding-vertical-m:                 $global-xlarge-margin !default;

$tile-xlarge-padding-vertical:                  $global-xlarge-margin !default;
$tile-xlarge-padding-vertical-m:                ($global-large-margin + $global-xlarge-margin) !default;

$tile-default-background:                       $global-background !default;

$tile-muted-background:                         $global-muted-background !default;

$tile-primary-background:                       $global-primary-background !default;
$tile-primary-color-mode:                       light !default;

$tile-secondary-background:                     $global-secondary-background !default;
$tile-secondary-color-mode:                     light !default;

