.lf-modal {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & hr {
    width: 60px;
    border-color: white;
    margin: 30px 0;
  }

  & sup {
    opacity: .5;
  }
}

.lf-modal-top {
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 95px;
}

@media(max-width: 639px) {
  .lf-modal-top {
    margin-top: 65px;
  }
}

// TODO: Once search is refactored, move this to transition-group.
.lf-search-results {
  & .lf-search-result {
    opacity: 0;
    animation: fadeInDown .75s normal forwards;
  }
}

.lf-search-results-count {
  font-size: $global-font-size;
}

.lf-icon-small {
  width: 16px;
  height: 16px;
}

.lf-search-form {
}

.lf-search-icon {
  transform: translateX(-45px);
  transition: all 200ms ease-in-out;
}

.lf-search-clear-icon {
  transform: translateX(-60px);
  transition: all 200ms ease-in-out;
  opacity: 0;
}

.lf-search-form-active {
  .lf-search-clear-icon {
    transform: translateX(-45px);
    opacity: 1;
  }
}

#search-modal {
  & input[type='text'] {
    background: none;
    border: none;
    border-bottom: 1px solid white;
    font-family: inherit;
    font-size: $global-medium-font-size;
    outline: none !important;
    color: inherit;
    line-height: 1.25;
    padding: 5px 25px 5px 0;

    &::placeholder {
      text-transform: uppercase;
      color: $global-muted-color;
    }
  }
}

#home-welcome-modal {
  .uk-close {
    top: 4px;
    right: 4px;

    svg {
      width: 20px;
      height: 20px;

      &:not(:first-child) {
        display: none;
      }
    }
  }

  .uk-modal-dialog {
    padding: 0;
    width: 840px;
    border: 6px solid #000;
  }

  .inner {
    display: flex;
    align-items: center;

    .container_img {
      flex: 0 0 380px;
      padding-right: 5px;
      background: #000;
    }

    .container_content {
      text-align: center;
      letter-spacing: -1px;
      font-size: 1.2em;
      padding: 0 25px;

      h1 {
        letter-spacing: -2px;
        font-size: 1.8em;
        margin-bottom: 10px;

        + p {
          margin-top: 0;
        }
      }

      p {
        line-height: 1.5;
      }

      img {
        width: 240px;
        display: block;
        margin: 0 auto 20px;
      }

      .cta {
        background: $global-primary-background;
        color: #fff;
        padding: 10px;
        display: inline-block;
        font-size: 16px;
        letter-spacing: -.4px;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  @media (max-width: 890px) {
    .uk-close {
      display: block;
      background: #fff;
      top: 2px;
      right: 3px;
      padding: 7px;
    }

    .uk-modal-dialog {
      width: 400px;
    }

    .inner {
      display: block;

      .container_img {
        padding-right: 0;
        padding-bottom: 5px;
      }

      .container_content {
        font-size: 1.05em;
        padding: 20px 15px;

        img {
          width: 210px;
        }
      }
    }
  }
}
