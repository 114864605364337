.mapboxgl-map {
  width: 100%;
  height: 100%;
  // overflow: visible !important;
}

.mapboxgl-canvas {
  position: static !important;
}

.mapboxgl-canvas-container, .mapboxgl-canvas {
  outline: none !important;
}

.mapboxgl-popup {
  @extend .uk-visible\@m;
  z-index: 100;

  .mapboxgl-popup-tip {
    // transform: scale(2);
  }
  // &.mapboxgl-popup-anchor-top .mapboxgl-popup-tip { filter: drop-shadow(0px -2px #1E00FF); }
  // &.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip { filter: drop-shadow(0px 2px #1E00FF); }
  &.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
  &.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
  &.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip { border-bottom-color: #1E00FF; }
  &.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
  &.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
  &.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip { border-top-color: #1E00FF; }
  &.mapboxgl-popup-anchor-left .mapboxgl-popup-tip { border-right-color: #1E00FF; }
  &.mapboxgl-popup-anchor-right .mapboxgl-popup-tip { border-left-color: #1E00FF; }
}

.mapboxgl-map .mapboxgl-popup-content {
  padding: 20px !important;
  max-width: 250px;
}

.mapboxgl-popup-content {
  font-family: $global-font-family;
  font-size: $global-font-size;
  border-radius: 0 !important;
  box-shadow: none !important;
  padding: 30px !important;
  max-width: 500px;
  border: 2px solid $global-primary-background;

  & .lf-card-title {
    font-size: 1.2rem;
    line-height: 1.5;
    border-left: none;
    padding-left: 0;
  }

  & .lf-card-tag {
    font-size: 10px;
  }
}

.lf-map-marker.mapboxgl-marker {
  width: 33px;
  height: 43px;
  background-image: url("../images/icons/pin.svg");
  top: -21.5px;
  pointer-events: none;
}

.lf-story-map-container {
  @extend .uk-padding;
  @media (max-width: $breakpoint-small-max) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
  }
}

.lf-story-map-container {
  .mapboxgl-map {
    height: 50vh;
    min-height: 550px;

    @media (max-width: $breakpoint-small-max) {
      min-height: 340px;
    }
  }
}
