// Name:            Thumbnav
// Description:     Component to create thumbnail navigations
//
// Component:       `uk-thumbnav`
//
// Modifier:        `uk-thumbnav-vertical`
//
// States:          `uk-active`
//
// ========================================================================


// Variables
// ========================================================================

$thumbnav-margin-horizontal:                     15px !default;
$thumbnav-margin-vertical:                       $thumbnav-margin-horizontal !default;
