.paginator {
  &-enter {
    opacity: 0;
    transform: translate3d(0, 30px, 0);
  }
  &-enter-active {
    animation: .75s;
    animation: fadeInDown .75s normal forwards ease-in-out;
  }
  &-leave-active {
  }
}

.paginator-timeline:last-of-type {
  .paginator-list-item .lf-story-card {
    @media (min-width: $breakpoint-medium) {
      border-bottom: 2px solid $global-muted-background;
    }
  }
  .paginator-list-item .lf-share-links-timeline {
    @media (max-width: $breakpoint-small-max) {
      border-bottom: 2px solid $global-muted-background;
    }
  }
}

.paginator-list-item {
  order: 2;

  & .lf-story-card {
    border-top: 2px solid $global-muted-background;
    position: relative;

    &::before {
      content: "";
      top: -2px;
      bottom: -2px;
      width: 3px;
      left: -3px;
      background: transparent;
      position: absolute;
      transition-duration: .2s;
    }

    &:hover::before {
      background: $global-link-color;
    }
  }

  &:hover + .paginator-timeline-item {
    color: $global-color;
  }
}

.paginator-timeline-item {
  order: 1;
  color: $global-muted-color;
  transition-duration: .2s;

  .paginator-label-content {
    z-index: 1;
    padding-right: 23px;
    text-align: right;
    flex-grow: 0;

    @media (max-width: $breakpoint-small-max) {
      padding: 20px !important;
      width: calc(100% - 34px);
    }
  }

  .paginator-timeline-author-label {
    @extend .uk-text-small;
    @extend .uk-text-uppercase;
    @extend .uk-margin-bottom;
  }

  .paginator-timeline-date-label {
    @extend .uk-text-small;
    @extend .uk-text-uppercase;
  }

  .paginator-timeline-location-label {
    @extend .uk-margin-top;
    @extend .uk-text-small;
    @extend .uk-text-uppercase;
  }
}

.paginator-timeline:last-of-type .paginator-timeline-item .paginator-timeline-line {
  @extend .uk-margin-bottom;
}

.paginator-timeline {
  & .paginator-list-item {
    @media (max-width: $breakpoint-small-max) {
      // margin-bottom: 0 !important;
    }
  }
}

.lf-paginator-continue-reading {
  width: 110px;
  margin: auto;
  text-align: center;
  line-height: 1.2;
}
