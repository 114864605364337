// Name:            Sticky
// Description:     Component to make elements sticky in the viewport
//
// Component:       `uk-sticky`
//
// Modifier:        `uk-sticky-fixed`
//
// States:          `uk-active`
//
// ========================================================================


// Variables
// ========================================================================

$sticky-z-index:                                 $global-z-index - 20 !default;

$sticky-animation-duration:                      0.2s !default;
$sticky-reverse-animation-duration:              0.2s !default;
