// Name:            Transition
// Description:     Utilities for transitions
//
// Component:       `uk-transition-*`
//
// Modifiers:       `uk-transition-fade`
//                  `uk-transition-scale-up`
//                  `uk-transition-scale-down`
//                  `uk-transition-slide-top-*`
//                  `uk-transition-slide-bottom-*`
//                  `uk-transition-slide-left-*`
//                  `uk-transition-slide-right-*`
//                  `uk-transition-opaque`
//                  `uk-transition-slow`
//
// Sub-objects:     `uk-transition-toggle`,
//                  `uk-transition-active`
//
// States:          `uk-hover`
//                  `uk-active`
//
// ========================================================================


// Variables
// ========================================================================

$transition-duration:                            0.3s !default;

$transition-scale:                               1.1 !default;

$transition-slide-small-translate:               10px !default;
$transition-slide-medium-translate:              50px !default;

$transition-slow-duration:                       0.7s !default;
