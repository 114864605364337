.lf-link-blue {
  color: $base-link-color !important;
}

.lf-insta-grid {
  overflow: hidden;
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  > div {
    flex: 1 1 100px;

    > img {
      max-width: 100%;
      height: auto;
    }
  }

  @media(max-width: 960px) {
    > div {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 105px;
    }
  }
}

@media(min-width: 1140px) {
  .lf-insta-grid.js-instagram-images > div:nth-child(1n+16) {
    display: none !important;
  }
}

@media(max-width: 960px) {
  .lf-insta-grid.js-instagram-images > div:nth-child(1n+19) {
    display: none;
  }

  .lf-insta-grid {
    position: relative;
    top: 10px;
  }
}
@media(max-width: 906px) {
  .lf-insta-grid.js-instagram-images > div:nth-child(1n+17) {
    display: none;
  }
}
@media(max-width: 524px) {
  .lf-insta-grid.js-instagram-images > div:nth-child(1n+9) {
    display: none;
  }
  .lf-insta-grid {
    position: relative;
    top: initial;
  }
}

@media (max-width: 959px) {
  .lf-contribute-container .lf-contribute-image {
    height: initial !important;
  }
}

@media (max-width: 475px) {
  .lf-contribute-container .lf-contribute-image {
    display: flex;
    align-items: flex-end;
  }

  .lf-insta-grid {
    > div {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 25%;

      > img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}

.lf-insta-grid-mobile {
  display: flex;
  flex-wrap: wrap;

  > div {
    flex: 1 1 10%;
  }
}

@media(max-width: 700px) {
  .lf-insta-grid-mobile {
    > div {
      flex: 1 1 20%;
    }
  }
}
