// Name:            Nav
// Description:     Defines styles for list navigations
//
// Component:       `uk-nav`
//
// Sub-objects:     `uk-nav-header`
//                  `uk-nav-divider`
//                  `uk-nav-sub`
//
// Modifiers:       `uk-nav-parent-icon`
//                  `uk-nav-default`
//                  `uk-nav-primary`
//                  `uk-nav-center`
//
// States:          `uk-active`
//                  `uk-parent`
//                  `uk-open`
//                  `uk-touch`
//
// ========================================================================


// Variables
// ========================================================================

$nav-item-padding-vertical:                      5px !default;
$nav-item-padding-horizontal:                    0 !default;

$nav-sublist-padding-vertical:                   5px !default;
$nav-sublist-padding-left:                       15px !default;
$nav-sublist-deeper-padding-left:                15px !default;
$nav-sublist-item-padding-vertical:              2px !default;

$nav-parent-icon-width:                          ($global-line-height * 1em) !default;
$nav-parent-icon-height:                         $nav-parent-icon-width !default;
$nav-parent-icon-color:                          $global-color !default;

$nav-header-padding-vertical:                    $nav-item-padding-vertical !default;
$nav-header-padding-horizontal:                  $nav-item-padding-horizontal !default;
$nav-header-font-size:                           $global-small-font-size !default;
$nav-header-text-transform:                      uppercase !default;
$nav-header-margin-top:                          $global-margin !default;

$nav-divider-margin-vertical:                    5px !default;
$nav-divider-margin-horizontal:                  0 !default;

$nav-default-item-color:                         $global-muted-color !default;
$nav-default-item-hover-color:                   $global-color !default;
$nav-default-item-active-color:                  $global-emphasis-color !default;
$nav-default-header-color:                       $global-emphasis-color !default;
$nav-default-divider-border-width:               $global-border-width !default;
$nav-default-divider-border:                     $global-border !default;
$nav-default-sublist-item-color:                 $global-muted-color !default;
$nav-default-sublist-item-hover-color:           $global-color !default;

$nav-primary-item-font-size:                     $global-large-font-size !default;
$nav-primary-item-line-height:                   $global-line-height !default;
$nav-primary-item-color:                         $global-muted-color !default;
$nav-primary-item-hover-color:                   $global-color !default;
$nav-primary-item-active-color:                  $global-emphasis-color !default;
$nav-primary-header-color:                       $global-emphasis-color !default;
$nav-primary-divider-border-width:               $global-border-width !default;
$nav-primary-divider-border:                     $global-border !default;
$nav-primary-sublist-item-color:                 $global-muted-color !default;
$nav-primary-sublist-item-hover-color:           $global-color !default;

$internal-nav-parent-close-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22#000%22%20stroke-width%3D%221.1%22%20points%3D%2210%201%204%207%2010%2013%22%3E%3C%2Fpolyline%3E%0A%3C%2Fsvg%3E" !default;
$internal-nav-parent-open-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22#000%22%20stroke-width%3D%221.1%22%20points%3D%221%204%207%2010%2013%204%22%3E%3C%2Fpolyline%3E%0A%3C%2Fsvg%3E" !default;



// Inverse
// ========================================================================

$inverse-nav-parent-icon-color:                 $inverse-global-color !default;
$inverse-nav-default-item-color:                $inverse-global-muted-color !default;
$inverse-nav-default-item-hover-color:          $inverse-global-color !default;
$inverse-nav-default-item-active-color:         $inverse-global-emphasis-color !default;
$inverse-nav-default-header-color:              $inverse-global-emphasis-color !default;
$inverse-nav-default-divider-border:            $inverse-global-border !default;
$inverse-nav-default-sublist-item-color:        $inverse-global-muted-color !default;
$inverse-nav-default-sublist-item-hover-color:  $inverse-global-color !default;

$inverse-nav-primary-item-color:                $inverse-global-muted-color !default;
$inverse-nav-primary-item-hover-color:          $inverse-global-color !default;
$inverse-nav-primary-item-active-color:         $inverse-global-emphasis-color !default;
$inverse-nav-primary-header-color:              $inverse-global-emphasis-color !default;
$inverse-nav-primary-divider-border:            $inverse-global-border !default;
$inverse-nav-primary-sublist-item-color:        $inverse-global-muted-color !default;
$inverse-nav-primary-sublist-item-hover-color:  $inverse-global-color !default;

