// Name:            Comment
// Description:     Component to create nested comments
//
// Component:       `uk-comment`
//
// Sub-objects:     `uk-comment-body`
//                  `uk-comment-header`
//                  `uk-comment-title`
//                  `uk-comment-meta`
//                  `uk-comment-avatar`
//                  `uk-comment-list`
//
// Modifier:        `uk-comment-primary`
//
// ========================================================================


// Variables
// ========================================================================

$comment-header-margin-bottom:                   $global-margin !default;

$comment-title-font-size:                        $global-medium-font-size !default;
$comment-title-line-height:                      1.4 !default;

$comment-meta-font-size:                         $global-small-font-size !default;
$comment-meta-line-height:                       1.4 !default;
$comment-meta-color:                             $global-muted-color !default;

$comment-list-margin-top:                        $global-large-margin !default;
$comment-list-padding-left:                      30px !default;
$comment-list-padding-left-m:                    100px !default;
