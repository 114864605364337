// Name:            Marker
// Description:     Component to create a marker icon
//
// Component:       `uk-marker`
//
// ========================================================================


// Variables
// ========================================================================

$marker-padding:                                  5px !default;
$marker-background:                               $global-secondary-background !default;
$marker-color:                                    $global-inverse-color !default;

$marker-hover-color:                              $global-inverse-color !default;


// Inverse
// ========================================================================

$inverse-marker-background:                   $global-muted-background !default;
$inverse-marker-color:                        $global-color !default;
$inverse-marker-hover-color:                  $global-color !default;

