// Name:            Form
// Description:     Styles for forms
//
// Component:       `uk-form-*`
//                  `uk-input`
//                  `uk-select`
//                  `uk-textarea`
//                  `uk-radio`
//                  `uk-checkbox`
//                  `uk-legend`
//                  `uk-fieldset`
//
// Sub-objects:     `uk-form-custom`
//                  `uk-form-stacked`
//                  `uk-form-horizontal`
//                  `uk-form-label`
//                  `uk-form-controls`
//                  `uk-form-icon`
//                  `uk-form-icon-flip`
//
// Modifiers:       `uk-form-small`
//                  `uk-form-large`
//                  `uk-form-danger`
//                  `uk-form-success`
//                  `uk-form-blank`
//                  `uk-form-width-xsmall`
//                  `uk-form-width-small`
//                  `uk-form-width-medium`
//                  `uk-form-width-large`
//                  `uk-form-controls-text`
//
// ========================================================================


// Variables
// ========================================================================

$form-height:                                    $global-control-height !default;
$form-line-height:                               $form-height !default;
$form-padding-horizontal:                        10px !default;
$form-padding-vertical:                          4px !default;

$form-background:                                $global-muted-background !default;
$form-color:                                     $global-color !default;

$form-focus-background:                          $global-muted-background !default;
$form-focus-color:                               $global-color !default;

$form-disabled-background:                       $global-muted-background !default;
$form-disabled-color:                            $global-muted-color !default;

$form-placeholder-color:                         $global-muted-color !default;

$form-small-height:                              $global-control-small-height !default;
$form-small-padding-horizontal:                  8px !default;
$form-small-line-height:                         $form-small-height !default;
$form-small-font-size:                           $global-small-font-size !default;

$form-large-height:                              $global-control-large-height !default;
$form-large-padding-horizontal:                  12px !default;
$form-large-line-height:                         $form-large-height !default;
$form-large-font-size:                           $global-medium-font-size !default;

$form-danger-color:                              $global-danger-background !default;
$form-success-color:                             $global-success-background !default;

$form-width-xsmall:                              50px !default;
$form-width-small:                               130px !default;
$form-width-medium:                              200px !default;
$form-width-large:                               500px !default;

$form-select-padding-right:                      20px !default;
$form-select-icon-color:                         $global-color !default;
$form-select-disabled-icon-color:                $global-muted-color !default;

$form-radio-size:                                16px !default;
$form-radio-margin-top:                          -4px !default;
$form-radio-background:                          darken($global-muted-background, 5%) !default;

$form-radio-checked-background:                  $global-primary-background !default;
$form-radio-checked-icon-color:                  $global-inverse-color !default;

$form-radio-checked-focus-background:            darken($global-primary-background, 10%) !default;

$form-radio-disabled-background:                 $global-muted-background !default;
$form-radio-disabled-icon-color:                 $global-muted-color !default;

$form-legend-font-size:                          $global-large-font-size !default;
$form-legend-line-height:                        1.4 !default;

$form-stacked-margin-bottom:                     $global-small-margin !default;

$form-horizontal-label-width:                    200px !default;
$form-horizontal-label-margin-top:               7px !default;
$form-horizontal-controls-margin-left:           215px !default;
$form-horizontal-controls-text-padding-top:      7px !default;

$form-icon-width:                                $form-height !default;
$form-icon-font-size:                            $global-font-size !default;
$form-icon-color:                                $global-muted-color !default;
$form-icon-hover-color:                          $global-color !default;

$internal-form-select-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22#000%22%20points%3D%2212%201%209%206%2015%206%22%3E%3C%2Fpolygon%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22#000%22%20points%3D%2212%2013%209%208%2015%208%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E%0A" !default;
$internal-form-radio-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22#000%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%3E%3C%2Fcircle%3E%0A%3C%2Fsvg%3E" !default;
$internal-form-checkbox-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22#000%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%2F%3E%0A%3C%2Fsvg%3E" !default;
$internal-form-checkbox-indeterminate-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22#000%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%3E%3C%2Frect%3E%0A%3C%2Fsvg%3E" !default;


// Inverse
// ========================================================================

$inverse-form-background:                       $inverse-global-muted-background !default;
$inverse-form-color:                            $inverse-global-color !default;
$inverse-form-focus-background:                 $inverse-global-muted-background !default;
$inverse-form-focus-color:                      $inverse-global-color !default;
$inverse-form-placeholder-color:                $inverse-global-muted-color !default;

$inverse-form-select-icon-color:                $inverse-global-color !default;

$inverse-form-radio-background:                 darken($inverse-global-muted-background, 5%) !default;

$inverse-form-radio-checked-background:         $inverse-global-primary-background !default;
$inverse-form-radio-checked-icon-color:         $inverse-global-inverse-color !default;

$inverse-form-radio-checked-focus-background:   darken($inverse-global-primary-background, 10%) !default;
