// Name:            Icon
// Description:     Component to create icons
//
// Component:       `uk-icon`
//
// Modifiers:       `uk-icon-image`
//                  `uk-icon-link`
//                  `uk-icon-button`
//
// States:          `uk-preserve`
//
// ========================================================================


// Variables
// ========================================================================

$icon-image-size:                                20px !default;

$icon-link-color:                                $global-muted-color !default;
$icon-link-hover-color:                          $global-color !default;
$icon-link-active-color:                         darken($global-color, 5%) !default;

$icon-button-size:                               36px !default;
$icon-button-border-radius:                      500px !default;
$icon-button-background:                         $global-muted-background !default;
$icon-button-color:                              $global-muted-color !default;

$icon-button-hover-background:                   darken($icon-button-background, 5%) !default;
$icon-button-hover-color:                        $global-color !default;

$icon-button-active-background:                  darken($icon-button-background, 10%) !default;
$icon-button-active-color:                       $global-color !default;



// Inverse
// ========================================================================

$inverse-icon-link-color:                       $inverse-global-muted-color !default;
$inverse-icon-link-hover-color:                 $inverse-global-color !default;
$inverse-icon-link-active-color:                $inverse-global-color !default;
$inverse-icon-button-background:                $inverse-global-muted-background !default;
$inverse-icon-button-color:                     $inverse-global-muted-color !default;
$inverse-icon-button-hover-background:          darken($inverse-icon-button-background, 5%) !default;
$inverse-icon-button-hover-color:               $inverse-global-color !default;
$inverse-icon-button-active-background:         darken($inverse-icon-button-background, 10%) !default;
$inverse-icon-button-active-color:              $inverse-global-color !default;

