// Name:            Utility
// Description:     Utilities collection
//
// Component:       `uk-panel-*`
//                  `uk-clearfix`
//                  `uk-float-*`
//                  `uk-overflow-*`
//                  `uk-resize-*`
//                  `uk-display-*`
//                  `uk-inline-*`
//                  `uk-height-*`
//                  `uk-responsive-*`
//                  `uk-preserve-width`
//                  `uk-border-*`
//                  `uk-box-shadow-*`
//                  `uk-box-shadow-bottom`
//                  `uk-dropcap`
//                  `uk-leader`
//                  `uk-logo`
//                  `uk-svg`
//                  `uk-blend-*`
//                  `uk-transform-*`
//                  `uk-transform-origin-*`
//
// States:          `uk-disabled`
//                  `uk-drag`
//                  `uk-dragover`
//                  `uk-preserve`
//
// ========================================================================


// Variables
// ========================================================================

$panel-scrollable-height:                        170px !default;
$panel-scrollable-padding:                       10px !default;
$panel-scrollable-border-width:                  $global-border-width !default;
$panel-scrollable-border:                        $global-border !default;

$height-small-height:                            150px !default;
$height-medium-height:                           300px !default;
$height-large-height:                            450px !default;

$border-rounded-border-radius:                   5px !default;

$box-shadow-duration:                            0.1s !default;

$box-shadow-bottom-height:                       30px !default;
$box-shadow-bottom-border-radius:                100% !default;
$box-shadow-bottom-background:                   #444 !default;
$box-shadow-bottom-blur:                         20px !default;

$dropcap-margin-right:                           10px !default;
$dropcap-font-size:                              (($global-line-height * 3) * 1em) !default;

$leader-fill-content:                            '.' !default;
$leader-fill-margin-left:                        $global-small-gutter !default;

$logo-font-size:                                 $global-large-font-size !default;
$logo-font-family:                               $global-font-family !default;
$logo-color:                                     $global-color !default;
$logo-hover-color:                               $global-color !default;

$dragover-box-shadow:                            0 0 20px rgba(100,100,100,0.3) !default;


// Inverse
// ========================================================================

$inverse-logo-color:                            $inverse-global-color !default;
$inverse-logo-hover-color:                      $inverse-global-color !default;

