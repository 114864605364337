@import "fade";
@import "slide";
@import "switch";

.pace {
  display: none;
}

.page {
  width: 100%;
  position: relative;
}

.page-container {
  width: 100%;
  position: absolute;
}

.custom-leave {
  & .mapboxgl-popup {
    display: none !important;
  }
}

.custom-leave-active {
  .lf-banner-title {
    animation: fadeOutDown 0.5s normal forwards ease-in-out;
  }

  .lf-banner-subtitle {
    animation: fadeOutDown 0.5s normal forwards ease-in-out;
    animation-delay: 0.15s;
  }

  .page-content, .arf-jumbo-cover, .arf-footer {
    animation: fadeOut 0.5s normal forwards ease-in-out;
    animation-delay: 0.5s;
  }

  .lf-featured-image {
    animation: zoomIn 1s normal forwards ease-in-out;
  }

  & .mapboxgl-popup {
    display: none !important;
  }
}

.custom-enter {
  .lf-banner-title,
  .lf-banner-subtitle,
  .page-content,
  .arf-footer,
  .arf-jumbo-cover,
  .lf-footer {
    opacity: 0;
  }

  .lf-featured-image.uk-cover {
    transform: translate(-50%, -50%) scale(1.1);
  }
}

// Safari hacks.
_:default:not(:root:root), .custom-enter-active {
  .lf-featured-image {
    animation: none !important;
  }
}

.lf-featured-image.uk-cover {
  transform: translate(-50%, -50%) scale(1);
}

.custom-enter-active {
  .lf-banner-title {
    opacity: 0;
    animation: fadeInDown 0.5s normal forwards ease-in-out;
  }

  .lf-banner-subtitle {
    opacity: 0;
    animation: fadeInDown 0.5s normal forwards ease-in-out;
    animation-delay: 0.15s;
  }

  .page-content, .arf-jumbo-cover, .arf-footer {
    opacity: 0;
    animation: fadeIn 0.5s normal forwards ease-in-out;
    animation-delay: 0.35s;
  }

  .lf-featured-image {
    // TODO: Lots of problems with this. Figure out later, if necessary.
    // animation: zoomOut 1.5s normal 0.5s forwards ease-in-out;
  }
}

.mapLoad-enter {
  opacity: 0;
}

.mapLoad-enter-active {
  opacity: 0;
  animation: fadeIn 0.5s normal forwards ease-in-out;
  animation-delay: .25s;
}

.swipe-enter-active {
  &#step1 {
    animation: slideRight 0.5s normal forwards ease-in-out;
  }
  &#step2 {
    animation: slideLeft 0.5s normal forwards ease-in-out;
  }
}

.swipe-leave {
  position: absolute;
}

.swipe-leave-active {
  &#step1 {
    animation: slideLeftLeave 0.5s normal forwards ease-in-out;
  }
  &#step2 {
    animation: slideRightLeave 0.5s normal forwards ease-in-out;
  }
  position: absolute;
}

.swipe-leave-to {
  position: absolute;
}

@keyframes zoomOut {
  from {
    transform: translate(-50%, -50%) scale(1.1);
  }

  to {
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes zoomIn {
  to {
    transform: translate(-50%, -50%) scale(1.05);
  }

  from {
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes slideLeft {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes slideRightLeave {
  from {
    transform: translateX(0%);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes slideRight {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes slideLeftLeave {
  from {
    transform: translateX(0%);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, 30px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInDownSmall {
  from {
    opacity: 0;
    transform: translate3d(0, 8px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, -30px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 30px, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -30px, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
