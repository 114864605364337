// Name:            Spinner
// Description:     Component to create a loading spinner
//
// Component:       `uk-spinner`
//
// ========================================================================


// Variables
// ========================================================================

$spinner-size:                                   30px !default;
$spinner-stroke-width:                           1 !default;
$spinner-radius:                                 floor(($spinner-size - $spinner-stroke-width) / 2) !default; // Minus stroke width to prevent overflow clipping
$spinner-circumference:                          round(2 * 3.141 * $spinner-radius) !default;
$spinner-duration:                               1.4s !default;

