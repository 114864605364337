// Name:            Section
// Description:     Component to create horizontal layout section
//
// Component:       `uk-section`
//
// Modifiers:       `uk-section-xsmall`
//                  `uk-section-small`
//                  `uk-section-large`
//                  `uk-section-xlarge`
//                  `uk-section-default`
//                  `uk-section-muted`
//                  `uk-section-primary`
//                  `uk-section-secondary`
//                  `uk-section-overlap`
//
// States:          `uk-preserve-color`
//
// ========================================================================


// Variables
// ========================================================================

$section-padding-vertical:                         $global-medium-margin !default;
$section-padding-vertical-m:                       $global-large-margin !default;

$section-xsmall-padding-vertical:                  $global-margin !default;

$section-small-padding-vertical:                   $global-medium-margin !default;

$section-large-padding-vertical:                   $global-large-margin !default;
$section-large-padding-vertical-m:                 $global-xlarge-margin !default;

$section-xlarge-padding-vertical:                  $global-xlarge-margin !default;
$section-xlarge-padding-vertical-m:                ($global-large-margin + $global-xlarge-margin) !default;

$section-default-background:                       $global-background !default;

$section-muted-background:                         $global-muted-background !default;

$section-primary-background:                       $global-primary-background !default;
$section-primary-color-mode:                       light !default;

$section-secondary-background:                     $global-secondary-background !default;
$section-secondary-color-mode:                     light !default;

