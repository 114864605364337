.lf-decoration-after-featured {
  @media (min-width: $breakpoint-medium) {
    position: relative;

    &::after {
      content: "";
      display: block;
      background: $global-link-color;
      width: 4px;
      bottom: -50px;
      top: calc(100% - 50px);
      position: absolute;
      left: calc(50% - 2px);
    }
  }
}

.lf-decoration-after {
  position: relative;
  z-index: 2;

  @media (min-width: $breakpoint-large) {
    margin-bottom: -30px;
  }

  &::after {
    content: "";
    display: block;
    background: $global-link-color;
    width: 3px;
    bottom: 0;
    top: 40px;
    position: absolute;
    left: calc(50% - 2px);
  }
}
