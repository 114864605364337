.lf-banner {
  // padding-top: 80px;
  @extend .uk-margin-medium-bottom;
}

.lf-banner--imageFluid {
  .lf-banner-container {
    min-height: 70vh;
  }

  .lf-banner-image {
    img {
      width: auto;
      max-width: none;
      height: 100%;
    }
  }

  @media (max-width: $breakpoint-small-max) {
    .lf-banner-container {
      min-height: auto;
    }

    .lf-banner-image {
      position: static !important; // Needed to override UiKit

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}

.lf-banner--11 {
  .lf-banner-background {
    background: linear-gradient(90deg,#042161 0,#5c94e2 100%);
  }
}

.lf-banner--12 {
  .lf-banner-background {
    background: linear-gradient(90deg,#042161 0,#5c94e2 100%);
  }
}

.lf-banner-shape {
  position: absolute;

  svg {
    width: 100%;
    height: 100%;
  }
}

.lf-banner-shape--bottom {
  bottom: -1px;
  left: -5px;
  right: -5px;
  height: 100px;
}

.lf-banner-shape--right {
  bottom: -1px;
  top: -1px;
  right: -1px;
  width: 33%;
}

.lf-banner-bgsvg {
  width: 100%;
  height: 100%;
}

.lf-basic-banner-cover {
  @media (max-width: $breakpoint-medium-max) {
    min-height: 340px;
    margin-top: 0 !important;
  }
}
