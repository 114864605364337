// Name:            Search
// Description:     Component to create the search
//
// Component:       `uk-search`
//
// Sub-objects:     `uk-search-input`
//                  `uk-search-toggle`
//
// Adopted:         `uk-search-icon`
//
// Modifier:        `uk-search-default`
//                  `uk-search-navbar`
//                  `uk-search-large`
//
// ========================================================================


// Variables
// ========================================================================

$search-color:                                       $global-color !default;
$search-placeholder-color:                           $global-muted-color !default;

$search-icon-color:                                  $global-muted-color !default;

$search-default-width:                               180px !default;
$search-default-height:                              $global-control-height !default;
$search-default-padding-horizontal:                  6px !default;
$search-default-background:                          $global-muted-background !default;
$search-default-focus-background:                    $search-default-background !default;

$search-default-icon-width:                          $global-control-height !default;

$search-navbar-width:                                400px !default;
$search-navbar-height:                               40px !default;
$search-navbar-background:                           transparent !default;
$search-navbar-font-size:                            $global-large-font-size !default;

$search-navbar-icon-width:                           40px !default;

$search-large-width:                                 500px !default;
$search-large-height:                                80px !default;
$search-large-background:                            transparent !default;
$search-large-font-size:                             $global-xxlarge-font-size !default;

$search-large-icon-width:                            80px !default;

$search-toggle-color:                                $global-muted-color !default;
$search-toggle-hover-color:                          $global-color !default;


// Inverse
// ========================================================================

$inverse-search-color:                          $inverse-global-color !default;
$inverse-search-placeholder-color:              $inverse-global-muted-color !default;

$inverse-search-icon-color:                     $inverse-global-muted-color !default;

$inverse-search-default-background:             $inverse-global-muted-background !default;
$inverse-search-default-focus-background:       $inverse-search-default-background !default;

$inverse-search-navbar-background:              transparent !default;

$inverse-search-large-background:               transparent !default;

$inverse-search-toggle-color:                   $inverse-global-muted-color !default;
$inverse-search-toggle-hover-color:             $inverse-global-color !default;
