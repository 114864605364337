// Name:            Countdown
// Description:     Component to create countdown timers
//
// Component:       `uk-countdown`
//
// Sub-objects:     `uk-countdown-number`
//                  `uk-countdown-separator`
//                  `uk-countdown-label`
//
// ========================================================================


// Variables
// ========================================================================

$countdown-item-line-height:                     70px !default;

$countdown-number-font-size:                     2rem !default; // 32px
$countdown-number-font-size-s:                   4rem !default; // 64px
$countdown-number-font-size-m:                   6rem !default; // 96px

$countdown-separator-font-size:                  1rem !default; // 16px
$countdown-separator-font-size-s:                2rem !default; // 32px
$countdown-separator-font-size-m:                3rem !default; // 48px
