// Name:            Button
// Description:     Styles for buttons
//
// Component:       `uk-button`
//
// Sub-objects:     `uk-button-group`
//
// Modifiers:       `uk-button-default`
//                  `uk-button-primary`
//                  `uk-button-secondary`
//                  `uk-button-danger`
//                  `uk-button-text`
//                  `uk-button-link`
//                  `uk-button-small`
//                  `uk-button-large`
//
// States:          `uk-active`
//
// ========================================================================

// @mixin hook-button(){}
// @mixin hook-button-hover(){}
// @mixin hook-button-focus(){}
// @mixin hook-button-active(){}
// @mixin hook-button-default(){}
// @mixin hook-button-default-hover(){}
// @mixin hook-button-default-active(){}
// @mixin hook-button-primary(){}
// @mixin hook-button-primary-hover(){}
// @mixin hook-button-primary-active(){}
// @mixin hook-button-secondary(){}
// @mixin hook-button-secondary-hover(){}
// @mixin hook-button-secondary-active(){}
// @mixin hook-button-danger(){}
// @mixin hook-button-danger-hover(){}
// @mixin hook-button-danger-active(){}
// @mixin hook-button-disabled(){}
// @mixin hook-button-small(){}
// @mixin hook-button-large(){}
// @mixin hook-button-text(){}
// @mixin hook-button-text-hover(){}
// @mixin hook-button-text-disabled(){}
// @mixin hook-button-link(){}
// @mixin hook-button-misc(){}


// Inverse
// ========================================================================

// @mixin hook-inverse-button-default(){}
// @mixin hook-inverse-button-default-hover(){}
// @mixin hook-inverse-button-default-active(){}
// @mixin hook-inverse-button-primary(){}
// @mixin hook-inverse-button-primary-hover(){}
// @mixin hook-inverse-button-primary-active(){}
// @mixin hook-inverse-button-secondary(){}
// @mixin hook-inverse-button-secondary-hover(){}
// @mixin hook-inverse-button-secondary-active(){}
// @mixin hook-inverse-button-text(){}
// @mixin hook-inverse-button-text-hover(){}
// @mixin hook-inverse-button-text-disabled(){}
// @mixin hook-inverse-button-link(){}

@mixin hook-inverse-button-default(){
  background: none;
  background-color: none;
  border: 1px solid $global-background;
  color: $global-background;
}
