.lf-button {
  border-radius: 4px;
  white-space: nowrap;
  padding: 0.1rem 1rem;
}

.custom_btn_a {
  color: #fff;
  background: #1E00FF;
  font-size: 1.1em;
  width: 100%;
  text-decoration: none;
  max-width: 170px;
  display: inline-block;
  text-align: center;
  line-height: 1.1;
  padding: 8px 12px;

  & + & {
    margin-left: 20px;
  }

  &:hover {
    color: #fff;
    background: #1E00FF;
    text-decoration: none;
  }

  @media (max-width: 767px) {
    & + & {
      margin-left: 5px;
    }
  }
}
