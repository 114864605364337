.slide-enter { transform: translateX(100%) }
.slide-enter-to { transform: translateX(0) }

.slide-leave { transform: translateX(0) }
.slide-leave-to { transform: translateX(-100%) }

.slide-enter-active,
.slide-leave-active {
  position: absolute;
  transition: all 0.35s ease-in-out;
}

.pace-running {
  .lf-pace-footer {
    opacity: 0;
    transition: 0s;
  }
}

.lf-pace-footer {
  transition: all 0.35s ease-in-out;
}